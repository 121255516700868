import './index.css'
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
// import { getIframe } from '../../apis/fixture.api';
import { useEffect, useState } from 'react';
import Loader from '../../components/common/Loader';
import { Button, Col, Row } from 'antd';
// import AdSenseBanner from '../Ads';
// import { ADS } from '../../utils/constant';
import PlayerDropdown from './playerDropdown';

  export default  function  Player({ fixture }: any) {
    const matches = useMediaQuery('(min-width: 768px)');
    const [sourceLink, setSourceLink] = useState<any>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [players, setPlayers] = useState<any[]>([]);
  
    const getFixtureFrame = async ()=> {
      setLoader(true);
      const website = 'freesport';
      const links = fixture?.game?.streamerLinks;
      const playersList = [];
      let streamSource = '';
      for (const item of links) {
        if (item?.source?.trim() === 'sheri007' || item?.source?.trim() === website) {
          if (!streamSource) {streamSource = item?.link};
          playersList.push({websiteLink: item?.link});
        }
        if (item?.source?.trim() === 'Sportsfeed24 Streams') {
          streamSource = item?.link;
          playersList.unshift({websiteLink: item?.link});
        }
        if (item?.source?.trim() === 'Elite') {
          if (!streamSource) {
            streamSource = item?.link;
            playersList.unshift({websiteLink: item?.link});
          } else {
            playersList.push({websiteLink: item?.link});
          }
        }
      }
      if (fixture?.game?.websiteLink) {
        if(playersList.length){
          if (fixture?.game?.websiteLink != playersList[0].websiteLink){
            streamSource = fixture?.game?.websiteLink;
            playersList.unshift({websiteLink: fixture?.game?.websiteLink});
          }
        }else {
          streamSource = fixture?.game?.websiteLink;
          playersList.unshift({websiteLink: fixture?.game?.websiteLink});
        }
      }
      setSourceLink(streamSource);
      setPlayers(playersList);
      setLoader(false);
    }

    const handlePlayerChange = (player: any) => {
      setSourceLink(player?.websiteLink);
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
      getFixtureFrame();
    }, []);
  
    return (
      <>
        {(matches || players.length < 5) && <div style={{marginBottom: '5px'}}>
          {players?.map((player:any, index:number) => {
            return <Button 
            key={index} 
            className='time-button' 
            style={{ backgroundColor: '#2298ff', marginRight: '5px' }} 
            shape="round"
            onClick={() => handlePlayerChange(player)}
            disabled={player?.websiteLink === sourceLink}
            >
              Player {index + 1} 
            </Button>
          })}
        </div>}
        {!matches && players?.length > 4 && <PlayerDropdown 
        players={players} 
        sourceLink={sourceLink} 
        handlePlayerChange={handlePlayerChange} 
      />}
      {
      sourceLink &&
        <Row className={matches ? 'video-player-container' : 'video-player-container-mobile'}>
          <Col span={22}>
          {!sourceLink && <div className='loading-placeholder'>Loading...</div>}
          <iframe frameBorder={0} marginHeight={0} marginWidth={0} height={matches ? '600':'250'} width={matches ? '1020':'350'} src={sourceLink} allowFullScreen={true} scrolling='no'></iframe>
          {loader ? <Loader /> : <></>}
          </Col>
        </Row>
      }
      </>
    );
  }
  